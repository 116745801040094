import angular from 'angular';
import {createRoute} from '../../core/config';

class EventListController {
  constructor($state, ToolbarService, $translate, _, FeedPostComposerDialogService, SessionService) {
    this.$state = $state;
    this.toolbarService = ToolbarService;
    this.$translate = $translate;
    this._ = _;
    this.groupedEvents = {};
    this.feedPostComposerDialogService = FeedPostComposerDialogService;
    this.sessionService = SessionService;
  }

  $onInit() {
    this.toolbarService.setPageTitle(this.$translate.instant('EVENT_LIST.TITLE'));
    this.currentUser = this.sessionService.getUser();
    this.loadEvents();
  }

  loadEvents() {
    let events = this.sortByDate(this.events);
    events = this.groupByDate(events);
    this.groupedEvents = events;
    this.hasLoadedEvents = true;
    this.hasEvents = !_.isEmpty(this.groupedEvents);
  }

  sortByDate(events) {
    return this._.sortBy(events, ['startDate', 'startTime']);
  }

  groupByDate(events) {
    return this._.groupBy(events, e => e.startDate.format('LL'));
  }

  goToEvent(eventId) {
    this.$state.go('app.event', {eventId});
  }

  createEvent(event) {
    this.feedPostComposerDialogService.open(event, {postType:'event', currentUser: this.currentUser})
      .then(post => this.goToEvent(post.eventId));
  }
}

angular.module('ll')
  .component('eventList', {
    template: require('./eventlist.html'),
    controller: EventListController,
    controllerAs: 'vm',
    bindings: {
      events: '<'
    }
  })
  .config(createRoute({
    name: 'app.eventList',
    component: 'eventList',
    url: '/eventlist',
    resolve: {
      events: (EventService) => EventService.getEvents()
    }
  }));
