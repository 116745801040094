import angular from 'angular';
import {createRoute} from '../../core/config';

class EventDetailController {
  constructor(uiAlertService, ToolbarService, ContentFeedbackService, TrackingService, PostService, $mdMedia, $translate, $state) {
    this.uiAlertService = uiAlertService;
    this.toolbarService = ToolbarService;
    this.contentFeedbackService = ContentFeedbackService;
    this.trackingService = TrackingService;
    this.postService = PostService;
    this.$mdMedia = $mdMedia;
    this.$translate = $translate;
    this.$state = $state;
  }

  $onInit() {
    this.showComments = false;
    this.toolbarService.configure({title: this.event.name, showBackButton: true});
    this.trackingService.track('Event', {eventId: this.event.eventId});
    this.updateAttendeesList();
  }

  onRsvpChange(status) {
    this.event.attendees = status.attendees;
    this.updateAttendeesList();
  }

  updateAttendeesList() {
    this.attendeesGoing = this.event.attendees.filter(a => a.status === 'going');
    this.attendeesMaybe = this.event.attendees.filter(a => a.status === 'maybe');
  }

  deleteEvent(event) {
    this.uiAlertService.showConfirm(this.$translate.instant('POST.DELETE.POST'), this.$translate.instant('POST.DELETE.ARE_YOU_SURE'))
      .then(() => this.postService.deletePost(this.event.postId))
      .then(() => this.$state.go('app.neighborhood'));
  }

  flagEvent(e) {
    this.contentFeedbackService.showReportContentDialog('feedpost', this.event.postId, e);
  }

  setLocationDetails(place) {
    this.placeDetails = place;
    this.event.location = place.name;
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  editEvent() {
    this.$state.go('app.eventEdit', {eventId: this.event.eventId});
  }

  updateLikes(likes) {
    this.event.likes = likes;
  }
}

angular.module('ll').component('eventDetail', {
  template: require('./event-detail.component.html'),
  controller: EventDetailController,
  controllerAs: 'vm',
  bindings: {
    event: '<',
    onDelete: '&'
  }
}).config(createRoute({
  name: 'app.event',
  component: 'eventDetail',
  url: '/event/:eventId',
  resolve: {
    event: ($transition$, EventService, ToastMessageService, $state, $translate) => EventService.getEvent($transition$.params().eventId).then((event) => {
      return event;
    }, (err) => {
      ToastMessageService.showMessage($translate.instant('ERROR_MESSAGE.EVENT_NOT_ACCESSIBLE'));
      $state.go('app.neighborhood');
    })
  }
}));
