import angular from 'angular';

angular.module('ll').factory('FeedPostFactory', FeedPostFactory);

function FeedPostFactory() {
  return {
    createFeedPost
  };

  function createFeedPost(feedPostData) {
    return new FeedPost(feedPostData);
  }
}

function FeedPost(data) {
  return {
    ...data,
    isEvent: function () {
      return this.postType === 'event';
    },
    isAdvertisement: function() {
      return this.postType === 'advertisement';
    },
    isPost: function () {
      return this.postType === 'post';
    },
    isHtmlPost: function () {
      return this.postType === 'htmlpost';
    },
    isAlert: function () {
      return this.postType === 'alert';
    },
    isPoll: function () {
      return this.postType === 'poll';
    },
    isFeedbackPost: function () {
      return this.postType === 'feedbackpost';
    },
    getTitle: function () {
      return this.isEvent() ? this.name : this.isPoll() ? this.question : this.subject;
    },
    getContent: function () {
      return this.isEvent() || this.isPoll() ? this.description : this.content;
    },
    getAuthor: function () {
      return getAuthor(this.author);
    },
    getComments: function () {
      return this.comments.reverse().map(getComment);
    },
    isBoardMessage: function(){
      return this.tags.find(t => t === 'property_board_message');
    }
  };

  function getAuthor(author) {
    return {
      userId: author.userId,
      name: `${author.firstName} ${author.lastName}`,
      roles: author.propertygroupRoles,
      profilePicture: author.profilePicture,
      labels: author.labels
    };
  }

  function getComment(comment) {
    return {
      ...comment,
      date: comment.createdAt,
      author: getAuthor(comment.author)
    };
  }
}
