import angular from 'angular';

class FeedPostComposerDialogController {

  constructor($mdDialog, options, LoaderInterceptorService) {
    this.$mdDialog = $mdDialog;
    this.command = {};
    this.options = options;
    this.showEventTab = options.showEventTab !== undefined ? options.showEventTab : true;
    this.showPollTab = options.showPollTab !== undefined ? options.showPollTab : true;
    this.showAlertTab = options.showAlertTab !== undefined ? options.showAlertTab : !this.options.currentUser.hasEnabledFeature('hide_alert_tab');
    this.selectedTab = this.getSelectedTab(options.postType);
    this.postTabTitleKey = options.postTabTitleKey !== undefined ? options.postTabTitleKey : 'NEWPOST.TYPE.POST';
    this.titleKey = options.titleKey !== undefined ? options.titleKey : 'NEWPOST.TITLE';
    LoaderInterceptorService.onLoading(isLoading => this.isLoading = isLoading);
  }

  $onInit() {
    this.isSending = false;
  }

  getSelectedTab(postType) {
    switch (postType) {
      case 'post':
        return 0;
      case 'event':
        return 1;
      case 'poll':
        return 2;
      case 'alert':
        return 3;
      default:
        return 0;
    }
  }

  selectType(postType) {
    this.options.postType = postType;
    this.showAttachmentButton = postType === 'post' || postType === 'event';
  }

  attachFile(file) {
    this.command = {
      name: 'attachFile',
      postType: this.options.postType,
      value: file
    };
  }

  post() {
    this.isSending = true;
    this.command = {
      name: 'save',
      postType: this.options.postType
    };
  }

  onSave(feedPost) {
    this.isSending = false;
    this.$mdDialog.hide(feedPost);
  }

  onSaveError(error) {
    this.isSending = false;
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

class FeedPostComposerDialogService {

  constructor($mdDialog, FeedService, $translate, $state) {
    this.$mdDialog = $mdDialog;
    this.$translate = $translate;
    FeedService.getGroupedPostGategories().then(c => this.postCategories = c);
    this.$state = $state;
  }

  open(event, options = null) {
    options = options || {};
    return this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: event,
      template: require('./feedpostcomposerdialog.html'),
      controller: FeedPostComposerDialogController,
      controllerAs: 'vm',
      fullscreen: true,
      locals: {
        options: {
          postType: options.postType,
          toOptions: this.getToOptions(options.currentUser, options.selectedTo),
          categoryOptions: this.getToCategoryOptions(this.postCategories, options.selectedCategory, options.categoryGroups),
          currentUser: options.currentUser,
          showEventTab: options.showEventTab,
          showPollTab: options.showPollTab,
          showAlertTab: options.showAlertTab,
          titleKey: options.titleKey,
          postTabTitleKey: options.postTabTitleKey
        }
      }
    }).then(post => this.goToPublication(post));
  }

  getToOptions(user, selectedTo) {
    let options = [];
    const showPropertyLevel = user.neighborhood ? user.neighborhood.showPropertyLevel : true;
    let doPreselectNeighborhood = true;
    if (showPropertyLevel && user.isPropertyMember()) {
      options.push(this.createToOption('brf', user.property.id, user.property.name, selectedTo === 'brf'));
      doPreselectNeighborhood = false;
    }
    options.push(this.createToOption('region', user.neighborhood.id, user.neighborhood.name, selectedTo === 'region' || doPreselectNeighborhood));
    return options;
  }

  createToOption(name, id, text, isPreSelected) {
    return {value: {name, id}, text, isPreSelected};
  }

  getToCategoryOptions(groupedCategories, selectedCategory, categoryGroups) {
    let groupedCategoriesCopy = {}; // Need to return a copy to not remove content from the cached object
    Object.keys(groupedCategories).forEach(function(key,index) {
      if (!categoryGroups || categoryGroups.find(allowedGroup => allowedGroup === key)) {
        let categories = groupedCategories[key];
        groupedCategoriesCopy[key] = categories
          .map(c => ({
            id: c.id,
            name: c.name,
            order: c.sortOrder,
            isPreSelected: c.id === selectedCategory
          }))
          .sort((a, b) => a.order - b.order);
      }
    });
    return groupedCategoriesCopy;
  }

  goToPublication(post) {
    switch (post.postType) {
      case 'event':
        this.$state.go('app.event', {eventId: post.eventId});
        break;
      case 'advertisement':
        this.$state.go('app.advertisement.detail', {id: post.id});
        break;
      default:
        this.$state.go('app.singlePost', {postId: post.postId});
        break;
    }
  }
}

angular.module('ll').service('FeedPostComposerDialogService', FeedPostComposerDialogService);
