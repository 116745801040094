import angular from 'angular';

class TrackingService {

  constructor(EnvironmentSettings, mixpanel, Version) {
    this.environmentSettings = EnvironmentSettings;
    this.mixpanel = mixpanel;
    this.version = Version;
  }

  isTrackingEnabled() {
    return this.environmentSettings.trackingEnabled;
  }

  static isUsingApp() {
    return navigator.userAgent.indexOf('gonative') >= 0;
  }

  // Provide isUsingApp also as an instance method so that it can be used from instances of this class
  isUsingApp() {
    return TrackingService.isUsingApp();
  }

  register(metadata = {}) {
    if (!this.isTrackingEnabled()) return;
    this.trackAppUsage(metadata);
    this.mixpanel.register(metadata);
  }

  trackAppUsage(metadata) {
    metadata['Using App'] = TrackingService.isUsingApp();
  }

  track(eventName, metadata = {}) {
    if (!this.isTrackingEnabled()) return;
    this.trackAppUsage(metadata);
    this.trackVersion(metadata);
    this.mixpanel.track(eventName, metadata);
  }

  trackTime(eventName) {
    this.mixpanel.time_event(eventName);
  }

  trackVersion(metadata) {
    metadata['System Environment'] = this.version.environment;
    metadata['Commit'] = this.version.commit;
    metadata['Build date'] = this.version.buildDate;
    return metadata;
  }

  reset() {
    this.mixpanel.reset();
  }

  identifyUser(userData) {
    if (!this.isTrackingEnabled()) return;

    this.idenfityUserMixPanel(userData);
  }

  idenfityUserMixPanel(userData) {
    this.mixpanel.identify(userData.userId);
    let propertyName = '', propertyType = '';
    if (userData.property) {
      [propertyName, propertyType] = [userData.property.name, userData.property.propertygrouptypename];
    }
    let neighborhood = userData.neighborhood;
    this.mixpanel.people.set({
      '$first_name': userData.firstName,
      '$last_name': userData.lastName,
      '$email': userData.contactInformation.email,
      'Property Name': propertyName,
      'Property Type': propertyType,
      '$region': neighborhood ? neighborhood.name : null,
      '$city': neighborhood ? neighborhood.municipality : null,
      'Registration Date': userData.created,
      'Using App': TrackingService.isUsingApp(),
      'System Environment': this.version.environment,
      'Commit': this.version.commit,
      'Build date': this.version.buildDate
    });
    this.mixpanel.people.set_once({
      'First Sign In': new Date()
    });
  }
}

angular.module('ll').service('TrackingService', TrackingService);
