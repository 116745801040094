import angular from 'angular';

angular.module('ll').filter('ContentLocation', ContentLocationFilter);

function ContentLocationFilter(SessionService, $translate) {
  return function (location) {
    let user = SessionService.getUser();
    switch (location) {
      case 'brf':
        return user.isPropertyMember() ? user.property.name : '';
      case 'region':
        return user.neighborhood.name;
      case 'area':
        return $translate.instant('MENU.SURROUNDINGS', {neighborhood: user.neighborhood.name});
      default:
        return '';
    }
  };
}
