import angular from 'angular';

class EventRsvpController {
  constructor(EventService) {
    this.eventService = EventService;
  }

  $onInit() {
    this.hideCounter = this.hideCounter || false;
    this.isGoingToEvent = false;
    this.isMaybeGoingToEvent = false;
    this.setEventAttendanceStatus(this.event);
    this.updateCounters(this.event);
  }

  $onChanges(changes) {
    if (changes.event) {
      this.event = changes.event.currentValue;
      this.setEventAttendanceStatus(this.event);
      this.updateCounters(this.event);
    }
  }

  goingToEvent() {
    let rsvpStatus = this.event.status === 'going' ? 'unattending' : 'going';
    this.eventService.rsvpEvent(this.event.eventId, rsvpStatus)
      .then(data => this.setEventAttendanceStatus(data))
      .then(data => this.updateCounters(data))
      .then(data => this.onRsvpChange({status: data}));
  }

  maybeGoingToEvent() {
    let rsvpStatus = this.event.status === 'maybe' ? 'unattending' : 'maybe';
    this.eventService.rsvpEvent(this.event.eventId, rsvpStatus)
      .then(data => this.setEventAttendanceStatus(data))
      .then(data => this.updateCounters(data))
      .then(data => this.onRsvpChange({status: data}));
  }

  updateCounters(rsvpData) {
    this.goingCounter = rsvpData.attendees.filter(a => a.status === 'going').length;
    this.maybeCounter = rsvpData.attendees.filter(a => a.status === 'maybe').length;
    return rsvpData;
  }

  setEventAttendanceStatus(data) {
    this.event.status = data.status;
    this.isGoingToEvent = data.status === 'going';
    this.isMaybeGoingToEvent = data.status === 'maybe';
    return data;
  }
}

angular.module('ll').component('eventRsvp', {
  template: require('./event-rsvp.component.html'),
  controller: EventRsvpController,
  controllerAs: 'vm',
  bindings: {
    event: '<',
    onRsvpChange: '&',
    hideCounter: '<'
  }
});
