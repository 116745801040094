/* eslint-disable camelcase */
import angular from 'angular';
import {createRoute} from '../../core/config';

// Hack to make the place id available to the component controller since it is not available in the
// onAddressSelected callback due to some sorcery in the ng-map library
let __hack_place = null;

class EventEditController {
  constructor(EnvironmentSettings, FeedService, $translate, ToolbarService, ToastMessageService, moment, $scope, $state, $timeout) {
    this.toolbarService = ToolbarService;
    this.environmentSettings = EnvironmentSettings;
    this.feedService = FeedService;
    this.toastMessageService = ToastMessageService;
    this.$translate = $translate;
    this.moment = moment;
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.toolbarService.configure({title: this.event.name, showBackButton: true});
    this.country = {country: this.environmentSettings.country};
    this.showComments = false;
    this.attachments = this.event.attachedImages || [];
    this.originalAttachments = this.attachments;
    this.startDate = this.event.startDate.toDate();
    this.now = this.moment().toDate();
    this.startTimeLabel = this.$translate.instant('NEWPOST.EVENT.START_TIME');
    this.hasLocationTextChanged = false;
  }

  save() {
    this.$scope.editPostForm.$setSubmitted();
    this.validateStartDate();

    if (!this.$scope.editPostForm.$valid) {
      return;
    }

    if (__hack_place) {
      this.event.location = __hack_place.name;
      this.event.placeId = __hack_place.place_id;
    } else if (this.hasLocationTextChanged) {
      // No autocomplete option has been chosen by the user, so just use the text that was entered
      // (already stored in this.event.location) and empty the placeId
      this.event.placeId = null;
    }

    let removedAttachments = this.originalAttachments.filter(a => !this.attachments.find(o => o.id === a.id)).map(o => o.id);
    let addedAttachments = this.attachments.filter(a => a.blob).map(a => a.blob);
    let startDate = this.moment(this.startDate);
    this.feedService.editEvent(
      this.event.postId,
      this.event.name,
      this.event.description,
      this.event.location,
      this.event.placeId,
      startDate.format('YYYY-MM-DD'),
      startDate.format('HH:mm'),
      removedAttachments,
      addedAttachments
    ).then(() => this.showMessage())
      .then(() => this.$state.go('app.event', {eventId: this.event.eventId}));
  }

  showMessage() {
    this.toastMessageService.showMessage(this.$translate.instant('NEWPOST.EVENT.EVENT_UPDATED'));
  }

  attachFile(file) {
    if (this.attachments.length <= 4) {
      this.attachments.push({...file, feed: file.url});
    }
  }

  setTime(hour, minute) {
    this.startDate.setHours(hour, minute);
    // timeout is used to prevent validateStartDate to complain about non-existing this.$scope.editPostForm.selectedHour/selectedMinute at first load.
    this.$timeout(() => {
      this.validateStartDate();
    }, 0);
  }

  removeAttachment(url) {
    this.attachments = this.attachments.filter(a => a.feed !== url);
  }

  onPlaceTextChanged() {
    this.hasLocationTextChanged = true;
  }

  onAddressSelected() {
    let place = this.getPlace();
    if (place) {
      __hack_place = place;
    }
  }

  validateStartDate() {
    let isStartTimeValid = this.moment(this.startDate).isAfter(this.moment());
    this.$scope.editPostForm.startDate.$setValidity('dateInPast', isStartTimeValid);
    this.$scope.editPostForm.selectedHour.$setValidity('dateInPast', isStartTimeValid);
    this.$scope.editPostForm.selectedMinute.$setValidity('dateInPast', isStartTimeValid);
  }
}

angular.module('ll').component('eventEdit', {
  template: require('./event-edit.component.html'),
  controller: EventEditController,
  controllerAs: 'vm',
  bindings: {
    event: '<',
    onDelete: '&'
  }
}).config(createRoute({
  name: 'app.eventEdit',
  component: 'eventEdit',
  url: '/event/:eventId/edit',
  resolve: {
    event: ($transition$, EventService) => EventService.getEvent($transition$.params().eventId)
  }
}));
