import angular from 'angular';

angular.module('ll').factory('EventService', EventService);

function EventService($http, LoggingService, EnvironmentSettings, FeedPostFactory, EventFactory) {
  let baseUrl = EnvironmentSettings.baseUrl;
  return {
    getEvents: function () {
      return $http.get(`${baseUrl}/api/event/events`)
        .then(resp => resp.data.map(EventFactory.createEvent), resp => LoggingService.error('Error getting events', resp));
    },
    getEvent: function (id) {
      return $http.get(`${baseUrl}/api/event/${id}`)
        .then(resp => EventFactory.createEvent(FeedPostFactory.createFeedPost(resp.data)), resp => LoggingService.error('Error getting event', resp));
    },
    rsvpEvent: function (eventId, rsvpStatus) {
      return $http.post(`${baseUrl}/api/event/${eventId}/rsvp`, {status: rsvpStatus})
        .then(resp => resp.data, resp => LoggingService.error('Error rsvping event', resp));
    }
  };
}
